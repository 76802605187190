window.$ = jquery;
window.jQuery = jquery;
window.jquery = jquery;
import 'select2';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap4-theme/dist/select2-bootstrap4.css';
window.moment = moment;

import 'bootstrap';
import datatables from "datatables.net";
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
window.DataTables = datatables;


import '@fortawesome/fontawesome-free';
import "../scss/main.scss"


if ($('.houses-graphic-outer-wrapper').length > 0) {
    import('./dashboard');
}